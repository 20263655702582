export const config = {
    "baseUrl": process.env.REACT_APP_BASE_URL,
    "googleClientId": process.env.REACT_APP_GOOGLE_CLIENT_ID,
    "googleClientSecret": process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    "googleRedirectUri": process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    "dashboardurl": process.env.REACT_APP_DASHBOARD_URL,
    "userURL":process.env.REACT_APP_BASE_URL_USER,
    "key": process.env.REACT_APP_KEY,
    "autoLoginURL": process.env.REACT_APP_AUTO_LOGIN_URL ,
    "supersetURL" : process.env.REACT_APP_SUPERSET_CALLONTHEGO,
    "internalDashboard" : process.env.REACT_APP_DASHBOARD_URL
};