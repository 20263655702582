import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { BUTTON, TEXT, URL } from "../constant";
import { saveAs } from "file-saver";
import PheonixTable from "../Components/PheonixTable";
import Papa from "papaparse";
import { config } from "../config";
import axios from "axios";
import "./styles.css";
import { api } from "../api";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import themestyle from "../themestyle";

interface ListOption {
  name: string;
  id: string;
}

interface Column {
  id: string;
  label: string;
}

const MAX_FILE_SIZE = 3 * 1024 * 1024;

const ImportContacts: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [lists, setLists] = useState<ListOption[]>([]);
  const [selectedListId, setSelectedListId] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [allContactsId, setAllContactsId] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [listSelected, setListSelected] = useState<boolean>(true);
  const [showFileInput, setShowFileInput] = useState<boolean>(false);
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { isOpen } = useSidebar();
  const token = localStorage.getItem("authtoken") || "";
  const userId = sessionStorage.getItem("user_id") || "";

  const extraHeight = useMemo(() => selectedListId.length * 40, [selectedListId]);

  useEffect(() => {
    const handleSessionMessage = (key: string, setMsg: (value: string) => void) => {
      const storedMessage = sessionStorage.getItem(key);
      if (storedMessage) {
        setShowAlert(true);
        setMsg(storedMessage);
        setTimeout(() => {
          setShowAlert(false);
          setMsg("");
          sessionStorage.removeItem(key);
        }, 5000);
      }
    };

    handleSessionMessage("messageimport", setMessage);
    handleSessionMessage("messageerrorimport", setErrorMessages);
  }, []);

  const fetchLists = useCallback(async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/v1/lists`, {
        headers: { "x-cotg-authtoken": token },
      });

      const listsData: ListOption[] = response.data.lists.map((item: { name: string; id: string }) => ({
        name: item.name,
        id: item.id,
      }));
      
      const allContactsList = listsData.find(list => list.name === "All Contacts");
      setAllContactsId(allContactsList?.id || "");
      setLists(listsData);

      if (response.data.lists[0]?.user_id) {
        sessionStorage.setItem("user_id", response.data.lists[0].user_id);
      }
    } catch (error) {
      console.error("Error fetching lists:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setShowAlert(false);

    if (file.size > MAX_FILE_SIZE) {
      setShowAlert(true);
      setErrorMessages(TEXT.FILELARGE);
      setSelectedFile(null);
      setProgress(0);
      return;
    }

    setSelectedFile(file);
    setUploadSuccess(false);
    setProgress(0);

    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 10;
      setProgress(progressValue);
      if (progressValue >= 100) {
        clearInterval(interval);
        setUploadSuccess(true);
      }
    }, 500);

    Papa.parse(file, {
      header: true,
      complete: (results: Papa.ParseResult<any>) => {
        if (results.data.length > 0) {
          const headers = Object.keys(results.data[0]);
          setTableData(results.data);
          setColumns(headers.map(header => ({
            id: header,
            label: header.charAt(0).toUpperCase() + header.slice(1),
          })));
        } else {
          setShowAlert(true);
          setErrorMessages(TEXT.NOFILE);
        }
      },
      error: () => {
        setShowAlert(true);
        setErrorMessages(TEXT.FILEERROR);
      },
    });
    setShowFileInput(true);
  }, []);

  const handleImportCSV = useCallback(async (file: File) => {
    if (!selectedListId.length) return;

    const query = `
      mutation UploadContacts($file: Upload!, $user_id: ID!, $list_id: ID!) {
        uploadContacts(file: $file, user_id: $user_id, list_id: $list_id) {
          success
          message
        }
      }
    `;

    const listIds = selectedListId.includes(allContactsId)
      ? selectedListId
      : [...selectedListId, allContactsId].filter(Boolean);

    const variables = {
      file: null,
      user_id: userId,
      list_id: listIds.join(","),
    };

    const formData = new FormData();
    formData.append("operations", JSON.stringify({ query, variables }));
    formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
    formData.append("0", file);

    try {
      setBackdropOpen(true);
      const response = await axios.post(api.baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-cotg-authtoken": token,
        },
      });

      if (response.data.errors) {
        console.error("GraphQL Errors:", response.data.errors);
        return;
      }

      setTimeout(() => {
        setBackdropOpen(false);
        const { success, message } = response.data.data.uploadContacts;
        setShowAlert(true);
        if (success) {
          setMessage(TEXT.IMPORTMSG);
          setStep(0);
          setSelectedFile(null);
          setSelectedListId([]);
          setSelectedValue("");
          setTableData([]);
          setTimeout(() => window.location.reload(), 10000);
        } else {
          setErrorMessages(message);
          if (message === "The contact already exists in the list.") {
            setStep(0);
            setSelectedFile(null);
            setSelectedListId([]);
            setSelectedValue("");
            setTableData([]);
          }
        }
        setTimeout(() => setShowAlert(false), 5000);
      }, 10000);
    } catch (error) {
      console.error("Network Error:", error);
      setBackdropOpen(false);
    }
  }, [selectedListId, allContactsId, token, userId]);

  const handleNext = useCallback(() => {
    if (step === 0 && !selectedListId.length) {
      setListSelected(false);
      return;
    }
    
    if (step === 1 && !selectedFile) return;

    if (step === 2 && selectedFile) {
      handleImportCSV(selectedFile);
    } else {
      setStep(prev => prev + 1);
      setShowFileInput(true);
    }
  }, [step, selectedListId, selectedFile, handleImportCSV]);

  const handlePrevious = useCallback(() => setStep(prev => Math.max(prev - 1, 0)), []);

  const StepContent = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="h6" sx={{ color: "#2f4050", fontSize: "13px", p: isMobile ? 1 : "20px 0 0 30px" }}>
              {TEXT.CONTENT} <strong>{TEXT.CONTENT_IN_BOLD}</strong>
            </Typography>
            <Box sx={{ p: isMobile ? 1 : "20px 10px 0 30px" }}>
              <Autocomplete<ListOption, true, true, false>
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                options={lists}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                  const ids = newValue.map(option => option.id);
                  setSelectedListId(ids);
                  setSelectedValue(newValue.map(option => option.name).join(", "));
                  setListSelected(true);
                }}
                value={selectedListId.map(id => lists.find(option => option.id === id)).filter((option): option is ListOption => !!option)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      size="small"
                      sx={{
                        background: themestyle.colors.primary,
                        color: "#FFFFFF",
                        borderRadius: "2.5px",
                        fontSize: "10px",
                        "& .MuiChip-deleteIcon": {
                          color: "#FFFFFF",
                          "&:hover": {
                            color: "#FFFFFF",
                          },
                        },
                      }}
                    />
                  ))
                }
                sx={{  fontSize: "10px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    style={{
                      height: isMobile ? "15px" :"30px",
                      fontSize: isMobile ? "7px" :"10px",
                      fontWeight: "100",
                      width: !isOpen && isMobile ? "200px":isMobile ? "250px" : "600px",
                    }}
                    InputProps={{ ...params.InputProps, sx: { fontSize: isMobile ? "7px" : "5px" } }}
                  />
                )}
              />
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h6" sx={{ color: "#2f4050", fontSize: "13px", p: isMobile ? 1 : "20px 0 0 30px" }}>
              {TEXT.UPLOAD_CONTENT}<br />{TEXT.UPLOAD_CONTENT_1}
            </Typography>
            <Box component="form" sx={{ m: isMobile ? 1 : "30px" }}>
              <Box
                className="input_container"
                sx={{
                  border: "1px solid #aaaaaa",
                  width: isMobile ? "90%" : "45%",
                  display: showFileInput ? "flex" : "none",
                  alignItems: "center",
                }}
              >
                <Box
                  component="label"
                  className="custom-file-upload"
                  onClick={() => document.getElementById("file-upload")?.click()}
                  sx={{
                    p: "5px 15px",
                    border: "1px solid #293846",
                    bgcolor: "#f3f3f4",
                    cursor: "pointer",
                    fontSize: isMobile ? "12px" : "13px",
                    "&:hover": { bgcolor: "#e0e0e0" },
                  }}
                >
                  Choose file
                </Box>
                <Typography sx={{ fontSize: isMobile ? "12px" : "14px", ml: 1 }}>
                  {selectedFile?.name || "No file chosen"}
                </Typography>
                <input
                  type="file"
                  id="file-upload"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Box>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6" sx={{ color: "#2f4050", fontSize: isMobile ? "12px" : "13px", p: isMobile ? 1 : "20px 0 0 30px" }}>
              {TEXT.PREVTEW_CONTENT}
            </Typography>
            <Box sx={{ height: 300, mt: 1, overflowY: "auto" }}>
              <PheonixTable columns={columns} data={tableData} />
            </Box>
          </>
        );
      default:
        return null;
    }
  }, [step, lists, selectedListId, selectedFile, showFileInput, isMobile, handleFileChange, columns, tableData]);

  return (
    <Box sx={{ color: "#f3f3f4", bgcolor: "#F3F3F4" }}>
      {isMobile && <Divider sx={{ my: 0.5 }} />}
      {showAlert && (message || errorMessages) && (
        <Alert
          severity={errorMessages ? "error" : "success"}
          action={
            <IconButton onClick={() => setShowAlert(false)}>
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          sx={{ color: errorMessages ? "#A94342" : "#3c763d", bgcolor: errorMessages ? "#F2DEDD" : undefined }}
        >
          {message || errorMessages}
        </Alert>
      )}
      
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <Box sx={{ width: isMobile ? "100%" : "70%" }}>
          <Typography variant="h3" sx={{ mt: isMobile ? "-1%" : "1%", color: "#2f4050", fontSize: 16, p: isMobile ? "0 0 0 10px" : "2px 2px 4px 25px" }}>
            {TEXT.Submit}
          </Typography>
          <Typography variant="h6" sx={{ width: isMobile ? "auto" : "120%", color: "#2f4050", fontSize: 13, p: isMobile ? 0 : "2px 2px 10px 25px" }}>
            {TEXT.ImportContacts}
          </Typography>
          
          <Box sx={{ p: isMobile ? "10px 0 0 10px" : "10px 0 0 20px", display: "flex", gap: isMobile ? 1 : 2 }}>
            {(["CHOOSE_LIST", "UPDATE_FILE", "PREVIEW"] as const).map((btn, idx) => (
              <Button
                key={btn}
                variant="contained"
                size="small"
                onClick={() => setStep(idx)}
                disabled={idx === 1 && !selectedListId.length || idx === 2 && !selectedFile}
                sx={{
                  color: "#ffffff",
                  width: isMobile ? "110%" : "100%",
                  fontSize: isMobile ? 11 : 14,
                  textTransform: "none",
                  justifyContent: "left",
                  bgcolor: step === idx 
                    ? idx === 0 && !listSelected ? "#ED5564" : themestyle.colors.primary
                    : themestyle.colors.hover,
                  "&:hover": { bgcolor: idx === 0 && !listSelected ? "#ED5564" : themestyle.colors.primary },
                  p: "8px",
                }}
              >
                {BUTTON[btn]}
              </Button>
            ))}
          </Box>
        </Box>

        <Paper
          elevation={0}
          sx={{
            p: isMobile ? 0 : "10px",
            m: isMobile ? "10px 0 0 10px" : "10px 0 0 30px",
            width: isTablet ? "90%" : isMobile ? "93%" : "95%",
            height: step === 2 ? 400 : isMobile ? `calc(200px + ${extraHeight}px)` : 200,
            color: "#999c9e",
            bgcolor: "#EEEEEE",
          }}
        >
          {StepContent}
        </Paper>

        <Box sx={{ p: "10px", m: isMobile ? "0 0 0 10px" : "0 0 0 30px", display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            disabled={step === 0}
            onClick={handlePrevious}
            sx={{
              color: "#ffffff",
              bgcolor: themestyle.colors.primary,
              textTransform: "none",
              fontSize: isMobile ? 11 : 14,
              width: isMobile ? "110%" : "100%",
              "&:hover": { bgcolor: themestyle.colors.primary },
            }}
          >
            {BUTTON.PERVIOUS}
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={step === 2 && tableData.length === 0}
            sx={{
              color: "#ffffff",
              bgcolor: themestyle.colors.primary,
              textTransform: "none",
              fontSize: isMobile ? 11 : 14,
              width: isMobile ? "110%" : "100%",
              "&:hover": { bgcolor: themestyle.colors.primary },
            }}
          >
            {step === 2 ? "Finish" : BUTTON.NEXT}
          </Button>
        </Box>

        <Box sx={{ p: isMobile ? "0 0 0 20px" : "0 10px 0 30px" }}>
          <Typography variant="h6" sx={{ color: "#2f4050", fontSize: 16, fontWeight: "bold", p: isMobile ? "10px 0 0" : "30px 0 0" }}>
            {TEXT.TEMPLATE_HERE}{" "}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const csvContent = ["first_name,last_name,number,email,notes"].join("\n");
                saveAs(new Blob([csvContent], { type: "text/csv" }), "cotg_template.csv");
              }}
              sx={{ color: "#428bca", textDecoration: "none" }}
            >
              here
            </Link>
          </Typography>
          <Box sx={{ p: isMobile ? "10px 0 0" : "8px 0 0" }}>
            <iframe
              width={isTablet ? "160%" : isMobile ? "100%" : "265%"}
              height={isTablet ? "600" : isMobile ? "200%" : "800"}
              src={URL.IMPORTCONTACTS_YT_LINK}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Box>
        </Box>

        <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={backdropOpen}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="inherit" />
            <Typography sx={{ mt: 2, color: "#FFFFFF" }}>{TEXT.FILE_UPLOAD}</Typography>
          </Box>
        </Backdrop>
      </Box>
    </Box>
  );
};

export default ImportContacts;