import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixMoveModalProps from "../interfaces/PheonixMoveModel";
import { useTheme, useMediaQuery } from "@mui/material";
import themestyle from "../themestyle";

const PheonixMoveModelModal: React.FC<PheonixMoveModalProps> = ({
  open,
  onClose,
  onConfirm,
  onMove,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
          width: "auto",
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "1px solid #0000001F",
          padding: "10px 30px 10px 10px",
          margin:isMobile? "10px 10px 500px 0px": "0px 10px 500px 100px",
        },
      }}
    >
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 0,
            color: "#999c9e",
          }}
        >
          <CloseIcon sx={{ color: "#999c9e",fontSize: "1rem" }} />
        </IconButton>
      <DialogActions>
        <Button
          onClick={onMove}
          sx={{
            textTransform: "none",
            borderRadius: "4px",
            
            width: isMobile? "60px":"84px",
            height:isMobile? "25px": "36px",
            backgroundColor: themestyle.colors.primary,
            color: "white",
            "&:hover": {
              backgroundColor: themestyle.colors.primary,
              color: "white",
            },
            "&:focus": {
              backgroundColor: themestyle.colors.primary,
              color: "white",
            },
          }}
        >
         Copy
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            textTransform: "none",
            color: "white",
            fontSize: "14px",
           
            width: isMobile? "60px":"84px",
            height:isMobile? "25px": "36px",
            backgroundColor: themestyle.colors.primary,
            "&:hover": {
              backgroundColor: themestyle.colors.primary,
              color: "white",
            },
          }}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PheonixMoveModelModal;
