import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PheonixTextField from "../Components/PheonixTextField";
import { BUTTON, TEXT, URL } from "../constant";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import themestyle from "../themestyle";

const Referral: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen, toggleSidebar } = useSidebar();

  const handleshare = () => {};

  const handleFacebookClick = () => {
    window.open(URL.FB_URL, "_blank");
  };

  const handleXClick = () => {
    window.open(URL.TWITTER_URL, "_blank");
  };

  const handleLinkedinClick = () => {
    window.open(URL.LINKEDIN_URL, "_blank");
  };

  return (
    <div style={{  backgroundColor: "#f3f3f4", height: "90vh" }}>
    <div
      style={{
        display: "flex",
        flexDirection: !isOpen && isMobile ? "column":isMobile ? "column" : "row",
        top: !isOpen && isMobile ? 10 :isMobile ? 10 : 0,
        backgroundColor: "#f3f3f4",
      }}
    >
      {isMobile &&
      <Divider style={{ marginBottom: "0.5rem", marginTop: !isOpen && isMobile ? "0.5rem" :isMobile ? "0.5rem" :"0rem" }} /> }
<div
  style={{
    display: "flex",
    justifyContent: "center", 
    alignItems: "center", 
    marginLeft: isMobile ? "0px" : "20px",
    marginTop: !isOpen && isMobile ? "0%" : isMobile ? "0%" : "3.5%"
  }}
      >
        <Paper
          elevation={0}
          style={{
            display: "flex",
            width: !isOpen && isMobile ? "90%" :isMobile ? "90%" :"100%",
            height: "300px",
            color: "#999c9e",
            backgroundColor: "#ffffff",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop:isMobile ?"0px" : "-20px"
          }}
        >
          <Typography
            sx={{
              fontSize: !isOpen && isMobile ? "24px" :isMobile ? "30px": "30px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#676a6c",
            }}
          >
            Share the Love
          </Typography>
          <Typography
            sx={{
              fontSize: !isOpen && isMobile ? "14px" :isMobile ? "16px":"16px",
              textAlign: "center",
              color: "#676a6c",
            }}
          >
            Give <span style={{ color: "#ff0000" }}>1 month</span> of
            CallOnTheGo Free
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <IconButton onClick={handleFacebookClick}>
              <FacebookIcon sx={{ color: "#316FF6", fontSize: !isOpen && isMobile ? "34px" :isMobile ? "38px":"40px" }} />
            </IconButton>
            <IconButton onClick={handleXClick}>
              <TwitterIcon sx={{ color: "#1DA1F2", fontSize:  !isOpen && isMobile ? "34px" :isMobile ? "38px":"40px" }} />
            </IconButton>
            <IconButton onClick={handleLinkedinClick}>
              <LinkedInIcon sx={{ color: "#0077b5", fontSize:  !isOpen && isMobile ? "34px" :isMobile ? "38px":"40px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PheonixTextField
              variant="outlined"
              style={{ height: "30px", width: !isOpen && isMobile ? "160px" :isMobile ? "200px" : "260px" }}
              type="text"
              placeholder="Enter Emails...(Commas separate)"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={handleshare}
              sx={{
                color: "#ffffff",
                textTransform: "none",
                width: !isOpen && isMobile ? "160px" :isMobile ? "200px" : "260px",
                backgroundColor: themestyle.colors.hover,
                "&:hover": {
                  backgroundColor: themestyle.colors.hover,
                },
              }}
            >
              {TEXT.SHARE}
            </Button>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <Typography
              style={{ color: "#CCCCCC", fontSize: "18px", cursor: "pointer" }}
            >
              <a
                href={TEXT.PREVIEW_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#CCCCCC", textDecoration: "none" }}
              >
                {BUTTON.SEE_PERVIEW}
              </a>
            </Typography>
          </Box>
        </Paper>
        </div>
        <div
          style={{ 
          display: "flex", 
          flexDirection: "column", 
          margin: isMobile ?"10px 0px 10px 20px" : "20px",
           }}
        >
          <Typography variant="h6" sx={{ color: "#676a6c", fontSize: "14px" }}>
            {TEXT.REFERRAL}
          </Typography>
          <Typography sx={{ textDecoration: "none", fontSize: "14px" }}>
            <a
              href={TEXT.REF_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#428BCA", textDecoration: "none" }}
            >
              {TEXT.REF_LINK}
            </a>
          </Typography>
        </div>    
    </div>
     </div>
  );
};

export default Referral;
