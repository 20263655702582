import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import { config } from "../config";

const AdminDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen } = useSidebar();

  return (
    <div>
      {isMobile && (
        <Divider
          style={{
            marginTop: !isOpen && isMobile ? "0.5rem" : "auto",
          }}
        />
      )}

      <iframe
        src={config.supersetURL}
        width="100%"
        height="800px"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default AdminDashboard;
