import React, {
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Box, Button, IconButton, Divider } from "@mui/material";
import { PheonixDataGrid } from "../Components/PhenoixDataGrid";
import MenuItem from "@mui/material/MenuItem";
import { BUTTON, MODALTEXT, TEXT } from "../constant";
import CustomModal from "../Components/PheonixModal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../config";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PheonixContactModal from "../Components/PheonixContactModel";
import Alert from "@mui/material/Alert";
import PheonixMoveModel from "../Components/PheonixMoveModel";
import { api } from "../api";
import {
  GridRowId,
  GridValidRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import PheonixListModal from "../Components/PheonixListModel";
import { useTheme, useMediaQuery } from "@mui/material";
import PheonixDeleteModal from "../Components/PheonixDeleteModel";
import { useSidebar } from "./sidebarcontext";
import PheonixEditListModal from "../Components/PheonixEditlistmodel";
import themestyle from "../themestyle";

interface CustomerOption {
  Name: string;
  Description: string;
}
interface Option {
  name: string;
  id: string;
  msg_group_id?: string;
}
export interface ContactOption {
  "First Name": string;
  "Last Name": string;
  Phone: string;
  Email: string;
  List: string;
}

interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  number: string;
  email: string;
  notes: string;
}

interface AllContactsProps {
  onDataSend: (data: string) => void;
}

const AllContacts: React.FC<AllContactsProps> = ({ onDataSend }) => {
  const token = localStorage.getItem("authtoken");
  const [listname, setListName] = useState("");
  const [listdescription, setListDescription] = useState("");
  const [firstname, setFirstName] = useState("");
  const [contacts, setContacts] = useState([]);
  const location = useLocation();
  const [listId, setListId] = useState<number>(0);
  const [contact, setContact] = useState<string | null>(null);
  const Title = `List: ${contact}`;
  const deleteTitle = contact;
  const [selectedValue, setSelectedValue] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [formData, setFormData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const [checkData, setCheckData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const [editFormData, setEditFormData] = useState<CustomerOption>({
    Name: "",
    Description: "",
  });
  const navigate = useNavigate();
  const [contactformData, setContactFormData] = useState<ContactOption>({
    "First Name": "",
    "Last Name": "",
    Phone: "",
    Email: "",
    List: "",
  });
  const [shouldFetchLists, setShouldFetchLists] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [names, setNames] = useState<Option[]>([]);
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedMsgGroupId, setSelectedMsgGroupId] = useState<string>("");
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [deletemodal, setDeleteModal] = useState(false);
  const handleConfirmContact = () => {
    handleNewContact();
  };
  const { isOpen, toggleSidebar } = useSidebar();
  var user_ids = sessionStorage.getItem("user_id") || "";
  useEffect(() => {
    if (location.state?.listId) {
      setListId(location.state.listId);
    }
    if (location.state?.contact?.name || location.state?.contact?.title) {
      setContact(location.state.contact.name || location.state.contact.title);
    }
  }, [location.state]);

  const handleConfirm = () => {
    handleEditList(listId, formData.Name, formData.Description);
  };

  const handleConfirmed = () => {
    handleNewList();
  };
  const msgApiToken = sessionStorage.getItem("msg_api_token") || "";
  const [Message, setMessage] = useState<string>("");
  const [Messages, setMessages] = useState<string>("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [selectedContactsDetails, setSelectedContactsDetails] = useState<
    {
      id: number;
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
    }[]
  >([]);

  const handleSelect = (
    selectedContacts: {
      id: number;
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
    }[]
  ) => {
    const selectedIDs = selectedContacts.map((contact) => contact.id);
    setSelectedContacts(selectedIDs);
    setSelectedContactsDetails(selectedContacts);
    console.log("Selected contacts:", selectedContacts);
  };

  const fetchoption = async () => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const lists: Option[] = response.data.lists.map(
        (item: { name: string; id: string; msg_group_id: string }) => ({
          name: item.name,
          id: item.id,
          msg_group_id: item.msg_group_id,
        })
      );

      if (contact && !lists.some((item) => item.name === contact)) {
        lists.unshift({ name: contact, id: "" });
      }

      const userIds = response.data.lists.map(
        (item: { user_id: string }) => item.user_id
      );
      const firstUserId = userIds.length > 0 ? userIds[0] : null;
      if (firstUserId) {
        sessionStorage.setItem("user_id", firstUserId);
      }

      setNames(lists.filter((item) => item.name !== contact));
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  useEffect(() => {
    fetchoption();
  }, [Title]);

  const getUserid = async (user_ids: string) => {
    if (!user_ids) {
      console.error("No user_id found in sessionStorage");
      return;
    }
    const query = `
      query GetUserById($id: ID!) {
        getUserById(id: $id) {
          id
          email
          msg_api_token
        }
      }
    `;

    const variables = { id: user_ids };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      const userData = response.data.data?.getUserById;

      if (userData) {
        if (userData.msg_api_token) {
          sessionStorage.setItem("msg_api_token", userData.msg_api_token);
        }
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
  };

  useEffect(() => {
    getUserid(user_ids as string);
  }, [user_ids, token]);

  const handleNewList = async () => {
    if (!formData.Name.trim()) {
      setErrorMessages("Name is required");
      return;
    }

    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const existingLists: Option[] = response.data.lists.map(
        (item: { name: string; id: string }) => ({
          name: item.name.toLowerCase(),
          id: item.id,
        })
      );

      const isDuplicate = existingLists.some(
        (list) => list.name === formData.Name.toLowerCase()
      );
      if (isDuplicate) {
        setErrorMessages(TEXT.GROUP_EXIST);
        return;
      }
      const createResponse = await axios.post(
        config.baseUrl + "/v1/lists",
        {
          name: formData.Name,
          description: formData.Description,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { name, id, description } = createResponse.data;
      const data = name;
      onDataSend(data);
      fetchoption();
      handleCloseModal();
      setListName("");
      setListDescription("");
      setShowAlert(true);
      setMessage(MODALTEXT.LISTSUCCESS);

      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
      }, 5000);

      setFormData({
        Name: "",
        Description: "",
      });
      setErrorMessages("");

      const msgGroupId = await msgCreateGroup(name);
      if (id && msgGroupId) {
        await insertGroupId(id, msgGroupId);
      }
    } catch (error: any) {
      console.error(MODALTEXT.ERROR, error);
      if (error.response?.data?.errors?.name) {
        setErrorMessages(TEXT.GROUP_EXIST);
        setShowAlert(true);
      } else {
        setErrorMessages("An unexpected error occurred.");
      }
    }
  };

  const msgCreateGroup = async (groupName: string): Promise<number | null> => {
    try {
      const response = await axios.get(
        `${config.autoLoginURL}/v2/?add_group=true&api_key=${msgApiToken}&group_name=${groupName}`
      );
      const { id } = response.data;

      return id;
    } catch (error) {
      console.error("Error adding the group:", error);
      return null;
    }
  };
  const insertGroupId = async (id: number, msgGroupId: number) => {
    const query = `
       mutation InsertMsgGroupId($id: Int!, $msgGroupId: Int!) {
        insertMsgGroupId(id: $id, msgGroupId: $msgGroupId) {
          success
          message
        }
      }
    `;

    const variables = {
      id: parseInt(id.toString(), 10),
      msgGroupId: parseInt(msgGroupId.toString(), 10),
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      const { success, message } = response.data.data.insertMsgGroupId;

      if (success) {
        console.info("Success:", message);
      } else {
        console.error("Error:", message);
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
  };

  const fetchListData = useCallback(
    async (listId: number) => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/v1/lists/${listId}`,
          {
            headers: { "x-cotg-authtoken": token },
          }
        );
        const { name, description } = response.data;
        setCheckData({ Name: name, Description: description });
        setEditFormData({ Name: name, Description: description });
        setContact(name);
      } catch (error) {
        console.error(MODALTEXT.ERROR_FETCHING, error);
        setErrorMessages("Failed to fetch list data.");
      }
    },
    [token]
  );
  useEffect(() => {
    if ((isEditModalOpen && listId) || (token && listId)) {
      fetchListData(listId);
    }
  }, [isEditModalOpen, listId, fetchListData, token]);
  const handleEditList = async (
    listId: number,
    name: string,
    description: string
  ) => {
    if (
      editFormData.Name === checkData.Name &&
      editFormData.Description === checkData.Description
    ) {
      setErrorMessages(MODALTEXT.NOLISTUPDATE);
      return;
    }

    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const existingLists = response.data.lists.map(
        (item: { name: string; description: string }) => ({
          name: item.name.trim().toLowerCase(),
          description: item.description?.trim().toLowerCase() || "",
        })
      );

      const isDuplicateName = existingLists.some(
        (item: { name: string }) =>
          item.name === checkData.Name.trim().toLowerCase() &&
          item.name !== editFormData.Name.trim().toLowerCase()
      );

      if (isDuplicateName) {
        setErrorMessages(TEXT.GROUP_EXIST);
        return;
      }

      const isSameNameDifferentCase =
        editFormData.Name.trim().toLowerCase() ===
          checkData.Name.trim().toLowerCase() &&
        editFormData.Name !== checkData.Name;
      if (isSameNameDifferentCase) {
        setErrorMessages(TEXT.GROUP_EXIST);
        return;
      }

      if (
        editFormData.Name.trim().toLowerCase() ===
          checkData.Name.trim().toLowerCase() &&
        editFormData.Description !== checkData.Description
      ) {
        console.info("Description changed but name is the same. Proceeding...");
      }

      if (
        isDuplicateName &&
        editFormData.Description !== checkData.Description
      ) {
        setErrorMessages(TEXT.GROUP_EXIST);
        return;
      }

      if (
        editFormData.Name === checkData.Name &&
        editFormData.Description !== checkData.Description
      ) {
        console.info("Valid update: Only description changed.");
      }
      await axios
        .put(
          `${config.baseUrl}/v1/lists/${listId}`,
          {
            name: checkData.Name.trim(),
            description: checkData.Description.trim(),
          },
          {
            headers: {
              "x-cotg-authtoken": token,
            },
          }
        )
        .then(async (response) => {
          fetchListData(listId);
          if (
            editFormData.Name.trim().toLowerCase() ===
              name.trim().toLowerCase() &&
            editFormData.Description.trim().toLowerCase() ===
              description.trim().toLowerCase()
          ) {
            setShowAlert(true);
            handleCloseModal();
            setMessages("Data is same");
            setTimeout(() => {
              setShowAlert(false);
              setMessages("");
            }, 5000);
          } else {
            const { name } = response.data;
            onDataSend(name);
            fetchListData(listId);
            setShowAlert(true);
            handleCloseModal();
            setMessage(MODALTEXT.LISTUPDATE);
            setTimeout(() => {
              setShowAlert(false);
              setMessage("");
            }, 5000);
            const msgGroupId = await getMsgGroupId(listId, user_ids);
            console.log("msgGroupId", msgGroupId);
            if (msgGroupId) {
              await msgEditGroup(msgGroupId, name);
            }
          }
        })
        .catch((error) => {
          console.error(MODALTEXT.ERROR, error);
          if (error.response?.data?.errors?.name) {
            setErrorMessages(TEXT.GROUP_EXIST);
            setShowAlert(true);
          } else {
            setErrorMessages("An unexpected error occurred.");
          }
        });
    } catch (error) {
      console.error("Error fetching existing lists:", error);
      setErrorMessages("Failed to check existing lists. Please try again.");
      setShowAlert(true);
    }
  };

  const msgEditGroup = async (
    group_id: string,
    name: string
  ): Promise<number | null> => {
    try {
      const response = await axios.get(
        `${config.autoLoginURL}/v2/?update_group=1&api_key=${msgApiToken}&group_id=${group_id}&group_name=${name}`
      );
      const { id } = response.data;
      console.log(" response.data;", id);
      return id;
    } catch (error) {
      console.error("Error adding the group:", error);
      return null;
    }
  };
  const handleDeleteList = async (listId: number) => {
    try {
      const msgGroupId = await getMsgGroupId(listId, user_ids);
      console.error("msgGroupId", msgGroupId, listId, user_ids);

      if (msgGroupId) {
        await msgDeleteGroup(msgGroupId);
        console.log("Message group deleted successfully.");
      }

      console.log("Waiting for 2 seconds before deleting the list...");
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const response = await axios.delete(
        `${config.baseUrl}/v1/lists/${listId}`,
        {
          headers: { "x-cotg-authtoken": token },
        }
      );

      console.log("List deletion response:", response);

      handleCloseModal();
      setShowAlert(true);
      setMessage(MODALTEXT.LISTDEL);
      localStorage.setItem("Delete", MODALTEXT.LISTDEL);

      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
      }, 5000);

      navigate("/lists");
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const msgDeleteGroup = async (group_id: string): Promise<number | null> => {
    try {
      const response = await axios.get(
        `${config.autoLoginURL}/v2/?remove_group=1&api_key=${msgApiToken}&group_id=${group_id}`
      );
      const { id } = response.data;
      console.log(" response.data;", id);
      return id;
    } catch (error) {
      console.error("Error adding the group:", error);
      return null;
    }
  };
  const handleRemoveContact = async (listId: number, selectedContacts: number[]) => {
    try {
      const msgContactData = await getMsgGroupIdContactId(selectedContacts, [listId.toString()]);
  
      if (!msgContactData) {
        console.error("Failed to retrieve msg_contact_id for deleted contacts.");
        return;
      }
  
      const removeResults = await Promise.all(
        msgContactData.map(({ msg_contact_id }) => {
          if (msg_contact_id) {
            return msgRemoveContact(selectedContactsDetails, msg_contact_id);
          }
          return Promise.resolve(null); 
        })
      );  
      console.log("Final removal responses:", removeResults);

      const deleteResponses = await Promise.all(
        selectedContacts.map((contactId) =>
          axios.delete(`${config.baseUrl}/v1/lists/${listId}/contact/${contactId}`, {
            headers: {
              "x-cotg-authtoken": token,
            },
          })
        )
      );
  
      const deletedContacts = deleteResponses.map((response) => response.data);
      console.log("Deleted contacts:", deletedContacts);
  
      const firstNames = deletedContacts.map((contact: any) => contact.first_name);
      const firstNamesString = firstNames.join(", ");
      onDataSend(firstNamesString);

  
      fetchData(listId);
      handleCloseModal();
      setShowAlert(true);
      setMessage(MODALTEXT.CONTACTDEL);
  
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
      }, 5000);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const getMsgGroupIdContactId = async (
    contactids: number[], 
    listIds: string[]
  ): Promise<{ msg_contact_id: string | null; msg_group_id: string | null }[] | null> => {
    const query = `
      query GetMsgContactIdGroupID($contactids: [String!]!, $listids: [String!]!) {
        getMsgContactIdGroupID(contactids: $contactids, listids: $listids) {
          msg_contact_id
          msg_group_id
        }
      }`;
  
    const variables = {
      contactids: contactids.map(String),
      listids: listIds,
    };
  
    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Full Axios Response:", JSON.stringify(response.data, null, 2));
  
      const data = response.data?.data?.getMsgContactIdGroupID;
      if (data && Array.isArray(data)) {
        return data.map((item) => ({
          msg_contact_id: item.msg_contact_id ?? null,
          msg_group_id: item.msg_group_id ?? null,
        }));
      } else {
        console.error("Error: No valid data found.");
        return null;
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      return null;
    }
  };
  
  const msgRemoveContact = async (
    selectedContactsDetails: {
      id: number;
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
    }[],
    msg_contact_id: string
  ): Promise<
    { contact_id: string; phone: string; phoneTmp: string; status: string; message: string }[] | null
  > => {
    try {
      const removeResponses = await Promise.all(
        selectedContactsDetails.map((contact) => {
          let formattedPhone = contact.phone;
  
          if (/^\d{10}$/.test(contact.phone)) {
            formattedPhone = `%2B1${contact.phone}`; // Add %2B1 for 10-digit numbers
          } else if (/^\d{11}$/.test(contact.phone)) {
            formattedPhone = `%2B${contact.phone}`; // Add %2B for 11-digit numbers
          } // No changes for 12+ digit numbers
  
          return axios.get(
            `${config.autoLoginURL}/v2/?remove_number=true&api_key=${msgApiToken}&contact_id=${msg_contact_id}&phone=${formattedPhone}`
          );
        })
      );

      const result = removeResponses.map((response) => response.data);
      
      console.log("API Response:", result);
  
      return result;
    } catch (error) {
      console.error("Error removing the contacts:", error);
      return null;
    }
  }; 

  const getMsgGroupId = async (
    listId: number,
    user_ids: string
  ): Promise<string | null> => {
    console.log("Fetching msg group ID...", listId, user_ids);

    const query = `
      query GetMsgGroupId($groupids: [String!]!, $user_id: String!) {
        getMsgGroupId(groupids: $groupids, user_id: $user_id) {
          msg_group_ids
        }
      }`;

    const variables = {
      groupids: [String(listId)],
      user_id: user_ids,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "Full Axios Response:",
        JSON.stringify(response.data, null, 2)
      );

      const data = response.data?.data?.getMsgGroupId;
      if (
        data &&
        Array.isArray(data.msg_group_ids) &&
        data.msg_group_ids.length > 0
      ) {
        return data.msg_group_ids[0];
      } else {
        console.error("Error: No valid msg_group_id found.");
        return null;
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      return null;
    }
  };
  const handleNewContact = async () => {
    if (!contactformData.Phone) {
      setErrorMessage((prev: any) => ({
        ...prev,
        Phone: "Phone number is required.",
      }));
      return;
    }

    const msgContactId = await msgAddContact(contactformData);
    console.log("msgContactId", msgContactId);

    try {
      const response = await axios.post(
        config.baseUrl + "/v1/contacts",
        {
          list_ids: contactformData.List,
          first_name: contactformData["First Name"],
          last_name: contactformData["Last Name"],
          number: contactformData.Phone,
          email: contactformData.Email,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { first_name, id: contactId } = response.data;
      onDataSend(first_name);
      setShouldFetchLists(true);
      fetchData(Number(listId));
      setShowAlert(true);
      setMessage(MODALTEXT.CONTACTCREATE);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
      }, 5000);
      handleCloseModal();

      if (msgContactId && msgContactId.length > 0) {
        const validContacts = msgContactId.filter((item) => item.contact_id !== null);
        if (validContacts.length === 0) {
          console.warn("No valid contacts found. Skipping insertContactIdGroupId.");
          return;
        }
        const listids = Array.isArray(contactformData.List)
          ? contactformData.List.map(String)
          : contactformData.List.split(",").map((item) => item.trim());
        const contactids = validContacts.map(() => contactId.toString());
        const msg_contact_ids = validContacts.map((item) => item.contact_id.toString());
        const msg_group_ids = validContacts.map((item) => item.group_id.toString());
  
        console.log("Valid Contacts:", validContacts);
        console.log("Final Data to Insert:", { listids, contactids, msg_contact_ids, msg_group_ids });
        await insertContactIdGroupId(listids, contactids, msg_contact_ids, msg_group_ids);
      }
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const msgAddContact = async (
    contactData: any
  ): Promise<{ group_id: string; contact_id: number }[] | null> => {
    try {
      const response = await axios.get(
        `${config.autoLoginURL}/v2/?add_number_v2=${
          contactData.Phone
        }&api_key=${msgApiToken}&first_name=${
          contactData["First Name"]
        }&last_name=${contactData["Last Name"]}&email=${
          contactData.Email
        }&group_ids=${contactData.msg_group_id.join(",")}`
      );

      console.info("Message contact is created successfully", response.data);

      if (
        response.data?.status === 1 &&
        Array.isArray(response.data.contacts)
      ) {
        return response.data.contacts.map(
          (contact: { group_id: any; contact_id: any }) => ({
            group_id: contact.group_id,
            contact_id: contact.contact_id,
          })
        );
      }

      return null;
    } catch (error) {
      console.error("Error adding the group", error);
      return null;
    }
  };

  const insertContactIdGroupId = async (
    listids: string[],
    contactids: string[],
    msg_contact_ids: string[],
    msg_group_ids: string[]
  ) => {
    const query = `
      mutation InsertMsgContactIdGroupID(
        $listid: [String!]!,
        $contactid: [String!]!,
        $msg_contact_id: [String!]!,
        $msg_group_id: [String!]!
      ) {
        insertMsgContactIdGroupID(
          listid: $listid,
          contactid: $contactid,
          msg_contact_id: $msg_contact_id,
          msg_group_id: $msg_group_id
        ) {
          success
          message
          users {
            list_id
            contact_id
            msg_contact_id
            msg_group_id
          }
        }
      }
    `;
    const expandedListIds = contactids.map(() => listids); 
    console.log("expandedListIds", expandedListIds);
    const variables = {
      listid: listids.map(String),
      contactid: contactids.map(String),
      msg_contact_id: msg_contact_ids.map(String),
      msg_group_id: msg_group_ids.map(String),
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("GraphQL Response:", response.data);
      const { success, message, users } =
        response.data.data.insertMsgContactIdGroupID;

      if (success) {
        console.info("Success:", message, users);
      } else {
        console.error("Error:", message);
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
  };

  const insertContactIdGroupIds = async (
    listids: string[],
    contactids: string[],
    msg_contact_ids: string[],
    msg_group_ids: string[]
  ) => {
    const query = `
      mutation InsertMsgContactIdGroupID(
        $listid: [String!]!,
        $contactid: [String!]!,
        $msg_contact_id: [String!]!,
        $msg_group_id: [String!]!
      ) {
        insertMsgContactIdGroupID(
          listid: $listid,
          contactid: $contactid,
          msg_contact_id: $msg_contact_id,
          msg_group_id: $msg_group_id
        ) {
          success
          message
          users {
            list_id
            contact_id
            msg_contact_id
            msg_group_id
          }
        }
      }
    `;
    const expandedListIds = contactids.map(() => listids[0]); 
    const variables = {
      listid: expandedListIds.map(String),
      contactid: contactids.map(String),
      msg_contact_id: msg_contact_ids.map(String),
      msg_group_id: msg_group_ids.map(String),
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("GraphQL Response:", response.data);
      const { success, message, users } =
        response.data.data.insertMsgContactIdGroupID;

      if (success) {
        console.info("Success:", message, users);
      } else {
        console.error("Error:", message);
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
  };
  const handleEditContacts = (row: GridValidRowModel) => {
    const updatedContact: Contact = {
      id: row.id,
      first_name: row.first_name,
      last_name: row.last_name,
      number: row.number,
      email: row.email,
      notes: row.notes,
    };

    axios
      .put(
        `${config.baseUrl}/v1/contacts/${updatedContact.id}`,
        {
          first_name: updatedContact.first_name,
          last_name: updatedContact.last_name,
          number: updatedContact.number,
          email: updatedContact.email,
          notes: updatedContact.notes,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then(async (response) => {
        console.log("updatedContact", updatedContact, response);
        const msgData = await getMsgContactId(updatedContact.id);

        if (msgData && msgData.length > 0) {
          const validMsgData = msgData.filter(
            (item) => item.msg_contact_id !== null
          );
          for (const { msg_contact_id, msg_group_id } of validMsgData) {
            if (msg_contact_id) {
              msgEditContact(updatedContact, msg_contact_id);
            }
          }
        }
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const getMsgContactId = async (
    contactid: number
  ): Promise<
    { msg_contact_id: string | null; msg_group_id: string | null }[] | null
  > => {
    const query = `
      query GetMsgContactID($contactid: String!) {
        getMsgContactID(contactid: $contactid) {
          msg_contact_id
          msg_group_id
        }
      }`;

    const variables = {
      contactid: contactid.toString(),
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "Full Axios Response:",
        JSON.stringify(response.data, null, 2)
      );

      const data = response.data?.data?.getMsgContactID;

      if (data && Array.isArray(data)) {
        return data.map((item) => ({
          msg_contact_id: item.msg_contact_id ?? null,
          msg_group_id: item.msg_group_id ?? null,
        }));
      } else {
        console.error("Error: No valid data found.");
        return null;
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      return null;
    }
  };

  const msgEditContact = (contactData: Contact, msg_contact_id: string) => {
    axios
      .get(
        `${config.autoLoginURL}/v2/?update_contact_v2=${contactData.number}&contact_id=${msg_contact_id}&api_key=${msgApiToken}&first_name=${contactData.first_name}&last_name=${contactData.last_name}&email=${contactData.email}`
      )
      .then((response) => {
        console.info("Message group is created successfully", response.data);
      })
      .catch((error) => {
        console.error("Error adding the group", error);
      });
  };
  const handlecopy = async () => {
    if (!selectedContacts || selectedContacts.length === 0) {
      handleCloseModal();
      setShowAlert(true);
      setMessages("No contact is selected");
      setTimeout(() => {
        setShowAlert(false);
        setMessages("");
      }, 5000);
      return;
    }
    const query = `mutation CopyContacts($targetListId: String!, $contactIds: String, $act: Boolean!) {
      copyContacts(
        targetListId: $targetListId,
        contactIds: $contactIds,
        act: $act,
      ) {
        success,
        message
      }
    }`;

    const variables = {
      targetListId: String(selectedId),
      contactIds: String(selectedContacts),
      act: true,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { success, message } = response.data.data.copyContacts;
      if (success) {
        const data = selectedContacts.toString();
        onDataSend(data);
        fetchData(listId);
        setIsMoveOpen(false);
        setShowAlert(true);
        setMessage(MODALTEXT.COPY);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
        if (selectedMsgGroupId) {
          const msgContactId = await msgCopyContact(
            selectedContactsDetails,
            selectedMsgGroupId
          );

          if (msgContactId && msgContactId.length > 0) {
            const listids = [selectedId]; 
            const contactids = selectedContactsDetails.map(contact => contact.id.toString());
            const msg_contact_ids = msgContactId.map(item => item.contact_id.toString());
            const msg_group_ids = msgContactId.map(item => item.group_id.toString());        
            await insertContactIdGroupIds(listids, contactids, msg_contact_ids, msg_group_ids);
          }
        }
        setSelectedContacts([]);
      } else {
        setShowAlert(true);
        setMessages(TEXT.COPYERROR);
        setTimeout(() => {
          setShowAlert(false);
          setMessages("");
        }, 5000);
        setIsMoveOpen(false);
        setSelectedContacts([]);
      }
    } catch (error) {
      console.error("ERROR_MESSAGES.ERROR", error);
    }
  };

  const msgCopyContact = async (
    selectedContactsDetails: {
      id: number;
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
    }[],
    selectedMsgGroupId: string
  ): Promise<{ group_id: string; contact_id: number }[] | null> => {
    try {
      if (!selectedContactsDetails.length || !selectedMsgGroupId) {
        console.error("Invalid contact data or message group ID");
        return null;
      }
  
      console.log("Selected contacts details:", selectedContactsDetails);
      console.log("Selected message group ID:", selectedMsgGroupId);
        const contactRequests = selectedContactsDetails.map((contact) =>
        axios.get(
          `${config.autoLoginURL}/v2/?add_number_v2=${contact.phone}&api_key=${msgApiToken}&first_name=${contact.first_name}&last_name=${contact.last_name}&email=${contact.email}&group_ids=${selectedMsgGroupId}`
        )
      );
  
      const responses = await Promise.all(contactRequests);
  
      const msgContactIds: { contact_id: number; group_id: string }[] = [];
  
      responses.forEach((response, index) => {
        if (response.data?.status === 1 && response.data.contacts?.length > 0) {
          const contactInfo = response.data.contacts[0]; 
          console.info("Message contact created successfully:", contactInfo);
  
          msgContactIds.push({
            contact_id: contactInfo.contact_id, 
            group_id: contactInfo.group_id, 
          });
        } else {
          console.error("Failed to create contact:", selectedContactsDetails[index]);
        }
      });
  
      return msgContactIds.length > 0 ? msgContactIds : null;
    } catch (error) {
      console.error("Error adding the group:", error);
      return null;
    }
  };
  

  const handlemove = async () => {
    if (!selectedContacts || selectedContacts.length === 0) {
      handleCloseModal();
      setShowAlert(true);
      setMessages(TEXT.NO_CONTACT);
      setTimeout(() => {
        setShowAlert(false);
        setMessages("");
      }, 5000);
      return;
    }
    const query = `mutation CopyContacts($targetListId: String!, $contactIds: String,$currentListId:String  $act: Boolean!) {
    copyContacts(
      targetListId: $targetListId,
      contactIds: $contactIds,
      currentListId: $currentListId,
      act: $act,
    ) {
      success,
      message
    }
  }`;

    const variables = {
      targetListId: String(selectedId),
      contactIds: String(selectedContacts),
      currentListId: String(listId),
      act: false,
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );

      const { success, message } = response.data.data.copyContacts;
      if (success) {
        const data = selectedContacts.toString();
        onDataSend(data);
        fetchData(listId);
        setIsMoveOpen(false);
        setSelectedContacts([]);
        setShowAlert(true);
        setMessage(MODALTEXT.MOVE);
        setSelectedContacts([]);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      } else {
        setSelectedContacts([]);
        setShowAlert(true);
        setMessages(TEXT.MOVEERROR);
        setSelectedContacts([]);
        setTimeout(() => {
          setShowAlert(false);
          setMessages("");
        }, 5000);
        setIsMoveOpen(false);
      }
    } catch (error) {
      console.error("ERROR_MESSAGES.ERROR", error);
    }
  };

  const handleDeleteContact = async (selectedContacts: number[]) => {

    const msgContactData = await Promise.all(
      selectedContacts.map(async (contactId) => {
        return getMsgContactIds(contactId);
      })
    );

    const validMsgContacts = msgContactData
      .flat()
      .filter((contact) => contact?.msg_contact_id);

    const removeResults = await Promise.all(
      validMsgContacts.map(({ msg_contact_id }) =>
        msgDeleteContact(selectedContactsDetails, msg_contact_id!)
      )
    );

    console.log("Final Msg Remove Results:", removeResults);

    axios
      .post(
        config.baseUrl + "/v1/contacts/deletemultiple",
        {
          contact_ids: selectedContacts,
        },
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      )
      .then((response) => {
        const data = selectedContacts.toString();
        console.log("Deleted contacts:", response);
        onDataSend(data);
        fetchData(listId);
        handleCloseModal();
        setShowAlert(true);
        localStorage.setItem("listapi", "false");
        setMessage(MODALTEXT.DELCONTACT);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error(MODALTEXT.ERROR, error);
      });
  };

  const getMsgContactIds = async (contactid: number) => {
    const query = `
      query GetMsgContactID($contactid: String!) {
        getMsgContactID(contactid: $contactid) {
          msg_contact_id
          msg_group_id
        }
      }`;
  
    try {
      const response = await axios.post(
        api.baseUrl,
        { query, variables: { contactid: contactid.toString() } },
        {
          headers: {
            "x-cotg-authtoken": token,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Full Axios Response:", JSON.stringify(response.data, null, 2));
  
      const data = response.data?.data?.getMsgContactID;
  
      return Array.isArray(data)
        ? data.map((item) => ({
            msg_contact_id: item?.msg_contact_id ?? null,
            msg_group_id: item?.msg_group_id ?? null,
          }))
        : [];
    } catch (error) {
      console.error("API Request Failed:", error);
      return [];
    }
  };

  const msgDeleteContact = async (
    selectedContactsDetails: { id: number; first_name: string; last_name: string; phone: string; email: string }[],
    msg_contact_id: string
  ) => {
    try {
      const removeResponses = await Promise.all(
        selectedContactsDetails.map((contact) => {
          let formattedPhone = contact.phone;
  
          if (/^\d{10}$/.test(contact.phone)) {
            formattedPhone = `%2B1${contact.phone}`; 
          } else if (/^\d{11}$/.test(contact.phone)) {
            formattedPhone = `%2B${contact.phone}`;
          } 
  
          return axios.get(
            `${config.autoLoginURL}/v2/?remove_number=true&api_key=${msgApiToken}&contact_id=${msg_contact_id}&phone=${formattedPhone}`
          );
        })
      );
  
      const result = removeResponses.map((response) => response.data);
      console.log("API Response:", result);
      return result;
    } catch (error) {
      console.error("Error removing contacts from Msg API:", error);
      return null;
    }
  };
  
  useEffect(() => {
    if (listId) {
      // @ts-ignore
      fetchData(parseInt(listId));
    }
  }, [listId]);

  const fetchData = async (listId: number) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}/v1/lists/${listId}/contacts`,
        {
          headers: {
            "x-cotg-authtoken": token,
          },
        }
      );
      const reversedContacts = response.data.contacts;
      setContacts(reversedContacts);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  useEffect(() => {
    fetchlistid();
  }, []);
  const fetchlistid = async () => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });
      const ids = response.data.lists.map((item: { id: any }) => item.id);
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  const handleImport = () => {
    navigate("/imports/new");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = names.find(
      (option) => option.name === event.target.value
    );
    setSelectedName(event.target.value);
    if (selectedOption) {
      setSelectedId(selectedOption.id);
      setSelectedMsgGroupId(selectedOption.msg_group_id ?? "");
      console.log("Selected ID:", selectedOption.id);
      console.log("Selected Name:", selectedOption.msg_group_id);
    }
    setIsMoveOpen(true);
  };

  const handleOpenModal = (content: React.ReactNode) => {
    setModalContent(content);
    setIsModalOpen(true);
    setDeleteModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setIsNewModalOpen(false);
    setIsNewContactModalOpen(false);
    setIsMoveOpen(false);
    setContactFormData({
      "First Name": "",
      "Last Name": "",
      Phone: "",
      Email: "",
      List: "",
    });
    setSelectionModel([]);
    setDeleteModal(false);
  };
  const handleDeleteListWrapper = () => {
    handleDeleteList(listId);
  };
  const column = [
    {
      field: "name",
      headerName: "Name",
      width: isMobile ? 120 : 220,
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        const { first_name, last_name } = params.row;
        return `${first_name} ${last_name}`.trim();
      },
      valueSetter: (params: GridValueSetterParams) => {
        const fullName = params.value.split(" ");
        params.row.first_name = fullName[0];
        params.row.last_name = fullName.slice(1).join(" ");
        return params.row;
      },
    },
    {
      field: "number",
      headerName: "Phone",
      width: isMobile ? 120 : 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: isMobile ? 150 : 250,
      editable: true,
      renderCell: (params: { value: any }) => (
        <span>{params.value || "Add Email Here"}</span>
      ),
    },
    ...(!isMobile
      ? [
          {
            field: "notes",
            headerName: "Notes",
            width: 250,
            editable: true,
            renderCell: (params: { value: any }) => (
              <span>{params.value || "Add Notes Here"}</span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div style={{ top: 0, backgroundColor: "#f3f3f4", height: "130vh" }}>
      {isMobile && (
        <Divider
          style={{
            marginTop:
              !isOpen && isMobile ? "0.5rem" : isMobile ? "0.5rem" : "auto",
          }}
        />
      )}
      {Message && ShowAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {Message}
        </Alert>
      )}
      {Messages && ShowAlert && (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
            </IconButton>
          }
          style={{ color: "#A94342", background: "#F2DEDD" }}
        >
          {Messages}
        </Alert>
      )}

      <div
        style={{
          marginLeft: "10px",
          marginTop: "5px",
          display: "flex",
          flexDirection: !isOpen && isMobile ? "row" : isMobile ? "row" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection:
              !isOpen && isMobile ? "column" : isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
            <Button
              variant="contained"
              onClick={() => setIsEditModalOpen(true)}
              size="small"
              sx={{
                fontWeight: "normal",
                fontSize:
                  !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                border: "none",
                borderRadius: "0",
                color: "#676a6c",
                textTransform: "none",
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
            >
              {BUTTON.EDIT_LIST}
            </Button>
            <Button
              variant="contained"
              onClick={() => setDeleteModal(true)}
              size="small"
              sx={{
                fontWeight: "normal",
                fontSize:
                  !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                marginLeft:
                  !isOpen && isMobile ? "5px" : isMobile ? "5px" : "auto",
                border: "none",
                borderRadius: "0",
                textTransform: "none",
                color: "#ffffff",
                backgroundColor: "#ed5565",
                "&:hover": {
                  backgroundColor: "#ed5565",
                },
              }}
            >
              {BUTTON.DELETE_LIST}
            </Button>
          </div>

          <div style={{ marginBottom: isMobile ? "10px" : "0" }}>
            <Button
              variant="contained"
              onClick={() => setIsNewModalOpen(true)}
              size="small"
              sx={{
                fontWeight: "normal",
                fontSize:
                  !isOpen && isMobile ? "11px" : isMobile ? "12px" : "14px",
                border: "none",
                borderRadius: "0",
                color: "#ffffff",
                textTransform: "none",
                backgroundColor: themestyle.colors.primary,
                "&:hover": {
                  backgroundColor: themestyle.colors.primary,
                },
              }}
            >
              {BUTTON.NEW_LIST}
            </Button>
          </div>

          <Select
            value={selectedValue}
            onChange={handleSelectChange}
            variant="standard"
            displayEmpty
            sx={{
              marginLeft:
                !isOpen && isMobile ? "0px" : isMobile ? "0px" : "10px",
              marginTop: "auto",
              width:
                !isOpen && isMobile ? "130px" : isMobile ? "130px" : "200px",
              backgroundColor: "#FFFFFF",
              color: "#676a6c",
              fontSize:
                !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
            }}
          >
            <MenuItem
              value=""
              sx={{
                fontSize:
                  !isOpen && isMobile ? "11px" : isMobile ? "12px" : "14px",
                height: !isOpen && isMobile ? "0px" : isMobile ? "0px" : "30px",
              }}
            >
              <em>Move Contacts to</em>
            </MenuItem>
            {names.map((option, index) => (
              <MenuItem
                key={index}
                value={option.name}
                sx={{
                  fontSize:
                    !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                  height:
                    !isOpen && isMobile ? "0px" : isMobile ? "0px" : "30px",
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <PheonixMoveModel
            open={isMoveOpen}
            onClose={() => setIsMoveOpen(false)}
            onConfirm={handlemove}
            onMove={handlecopy}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "flex-end",
          }}
        >
          <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
            <Button
              variant="contained"
              onClick={() => setIsNewContactModalOpen(true)}
              size="small"
              sx={{
                fontWeight: "normal",
                fontSize:
                  !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                border: "none",
                borderRadius: "0",
                color: "#ffffff",
                textTransform: "none",
                backgroundColor: themestyle.colors.primary,
                "&:hover": {
                  backgroundColor: themestyle.colors.primary,
                },
              }}
            >
              {BUTTON.NEWCONTACT}
            </Button>
          </div>

          <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
            <Button
              variant="contained"
              onClick={handleImport}
              size="small"
              sx={{
                fontWeight: "normal",
                fontSize:
                  !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                border: "none",
                borderRadius: "0",
                textTransform: "none",
                color: "#676a6c",
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
              }}
            >
              {BUTTON.IMPORT}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile || isTablet ? "column" : "row",
              alignItems: isMobile || isTablet ? "flex-end" : "center",
            }}
          >
            {selectedContacts.length !== 0 && !isTablet && (
              <>
                <div style={{ marginBottom: isMobile ? "5px" : "0" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleDeleteContact(selectedContacts)}
                    size="small"
                    sx={{
                      fontWeight: "normal",
                      fontSize:
                        !isOpen && isMobile
                          ? "10px"
                          : isMobile
                          ? "12px"
                          : "14px",
                      border: "none",
                      borderRadius: "0",
                      textTransform: "none",
                      color: "#ffffff",
                      backgroundColor: "#ed5565",
                      "&:hover": {
                        backgroundColor: "#ed5565",
                      },
                    }}
                  >
                    {BUTTON.DELETE_CONTACT}
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleRemoveContact(listId, selectedContacts)
                    }
                    size="small"
                    sx={{
                      fontWeight: "normal",
                      fontSize:
                        !isOpen && isMobile
                          ? "10px"
                          : isMobile
                          ? "12px"
                          : "14px",
                      border: "none",
                      marginTop: isTablet ? "20px" : "0",
                      borderRadius: "0",
                      textTransform: "none",
                      color: "#ffffff",
                      backgroundColor: "#ed5565",
                      "&:hover": {
                        backgroundColor: "#ed5565",
                      },
                    }}
                  >
                    {BUTTON.REMOVE_CONTACT}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {
        <div
          style={{
            display: "flex",
            flexDirection: isMobile || isTablet ? "column" : "row",
            alignItems: isMobile || isTablet ? "flex-end" : "center",
          }}
        >
          {selectedContacts.length !== 0 && isTablet && (
            <>
              <div>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteContact(selectedContacts)}
                  size="small"
                  sx={{
                    fontWeight: "normal",
                    fontSize:
                      !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                    border: "none",
                    borderRadius: "0",
                    textTransform: "none",
                    color: "#ffffff",
                    backgroundColor: "#ed5565",
                    "&:hover": {
                      backgroundColor: "#ed5565",
                    },
                  }}
                >
                  {BUTTON.DELETE_CONTACT}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={() => handleRemoveContact(listId, selectedContacts)}
                  size="small"
                  sx={{
                    fontWeight: "normal",
                    fontSize:
                      !isOpen && isMobile ? "10px" : isMobile ? "12px" : "14px",
                    border: "none",
                    borderRadius: "0",
                    textTransform: "none",
                    color: "#ffffff",
                    backgroundColor: "#ed5565",
                    "&:hover": {
                      backgroundColor: "#ed5565",
                    },
                  }}
                >
                  {BUTTON.REMOVE_CONTACT}
                </Button>
              </div>
            </>
          )}
        </div>
      }

      <Box sx={{ boxShadow: "none" }}>
        <PheonixDataGrid
          columns={column}
          rows={contacts}
          checkboxSelections={true}
          rowSelectionModel={selectedContacts}
          onSelect={handleSelect}
          onEdit={handleEditContacts}
          pageSizeOptions={[10, 20, 30]}
          style={{
            border: "none",
            borderColor: "transparent",
            borderStyle: "solid solid none",
            backgroundColor: "#FFFFFF",
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",
            overflow: "auto",
            maxHeight: "100vh",
            height: "auto",
          }}
          title={Title}
        />
      </Box>
      <CustomModal open={isModalOpen} handleClose={handleCloseModal}>
        {modalContent}
      </CustomModal>
      <PheonixEditListModal
        open={isEditModalOpen}
        onClose={handleCloseModal}
        title={"Edit List"}
        fields={[
          {
            label: "Name",
            type: "text",
            value: checkData.Name,
            onChange: (e) =>
              setCheckData({ ...checkData, Name: e.target.value }),
          },
          {
            label: "Description",
            type: "text",
            value: checkData.Description,
            onChange: (e) =>
              setCheckData({ ...checkData, Description: e.target.value }),
          },
        ]}
        onConfirm={handleConfirm}
        confirmButtonText={BUTTON.UPDATE}
        formData={checkData}
        setFormData={setCheckData}
        isEditModal={true}
        errorMessage={errorMessages}
        setErrorMessage={setErrorMessages}
      />
      <PheonixListModal
        open={isNewModalOpen}
        onClose={handleCloseModal}
        title={"New List"}
        fields={[
          {
            label: "Name",
            type: "text",
            value: formData.Name,
            onChange: (e) => setFormData({ ...formData, Name: e.target.value }),
          },
          {
            label: "Description",
            type: "text",
            value: formData.Description,
            onChange: (e) =>
              setFormData({ ...formData, Description: e.target.value }),
          },
        ]}
        onConfirm={handleConfirmed}
        confirmButtonText={BUTTON.CREATE}
        formData={formData}
        setFormData={setFormData}
        isEditModal={true}
        errorMessage={errorMessages}
        setErrorMessage={setErrorMessages}
      />
      <PheonixContactModal
        open={isNewContactModalOpen}
        onClose={handleCloseModal}
        title={"New Contact"}
        fields={[
          {
            label: "First Name",
            type: "text",
            value: contactformData["First Name"],
            onChange: (e) =>
              setContactFormData({
                ...contactformData,
                "First Name": e.target.value,
              }),
          },
          {
            label: "Last Name",
            type: "text",
            value: contactformData["Last Name"],
            onChange: (e) =>
              setContactFormData({
                ...contactformData,
                "Last Name": e.target.value,
              }),
          },
          {
            label: "Phone",
            type: "text",
            value: contactformData.Phone,
            onChange: (e) =>
              setContactFormData({ ...contactformData, Phone: e.target.value }),
          },
          {
            label: "Email",
            type: "text",
            value: contactformData.Email,
            onChange: (e) =>
              setContactFormData({ ...contactformData, Email: e.target.value }),
          },
          {
            label: "List",
            type: "autocomplete",
            value: contactformData.List,
            onChange: (e) =>
              setContactFormData({ ...contactformData, List: e.target.value }),
          },
        ]}
        onConfirm={handleConfirmContact}
        confirmButtonText={BUTTON.CREATE}
        formData={contactformData}
        setFormData={setContactFormData}
        isEditModal={true}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <PheonixDeleteModal
        open={deletemodal}
        onClose={handleCloseModal}
        onConfirm={handleDeleteListWrapper}
        entity={deleteTitle}
      />
    </div>
  );
};

export default AllContacts;
