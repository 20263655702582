import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { config } from "../config";
import { useLocation } from "react-router-dom";
import { MODALTEXT } from "../constant";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import { api } from "../api";
import themestyle from "../themestyle";

interface Option {
  name: string;
  id: string;
}

const Search: React.FC = () => {
  const token = localStorage.getItem("authtoken");
  const [cardData, setCardData] = useState<any[]>([]);
  const [cardDataList, setCardDataList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const userId = sessionStorage.getItem("user_id");
  const [selectedListName, setSelectedListName] = useState("");
  const [filteredListOptions, setFilteredListOptions] = useState<Option[]>([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen, toggleSidebar } = useSidebar();

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("searchQuery") || "";
    setSearchQuery(query);
  }, [location.search]);

  const fetchSearchContact = async (userId: string, filter: string) => {
    const query = `query GetAllContact($userId: ID!, $filter: String) {
      getAllContact(user_id: $userId, filter: $filter ) {
          id
          firstName
          lastName
          number
          email
          notes
          user_id
          createdAt
          updatedAt
      }
  }`;
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables: {
            userId: userId,
            filter: searchQuery,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-cotg-authtoken": token,
          },
        }
      );
      const fetchedContacts = response.data.data.getAllContact;
      const cardData = fetchedContacts.map(
        (item: {
          id: string;
          firstName: string;
          lastName: string;
          number: string;
          company: string;
          notes: string;
        }) => ({
          id: item.id,
          title: `${item.firstName} ${item.lastName}`,
          number: item.number,
          company: item.company,
          notes: item.notes,
        })
      );

      setCardData(cardData);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      throw new Error("Failed to fetch contacts");
    }
  };

  const fetchListOptions = async (listNameQuery: string = "") => {
    try {
      const response = await axios.get(config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const lists: Option[] = response.data.lists.map(
        (item: { name: string; id: string }) => ({
          name: item.name,
          id: item.id,
        })
      );

      if (listNameQuery) {
        var filteredLists = lists.filter((list) =>
          list.name.toLowerCase().includes(listNameQuery.toLowerCase())
        );
        setFilteredListOptions(filteredLists);

        const cardDataList = filteredLists.map((item) => ({
          title: item.name,
          id: item.id,
        }));

        setCardDataList(cardDataList);
      }

      const cardDataList = lists.map((item) => ({
        title: item.name,
        id: item.id,
      }));
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      fetchListOptions(searchQuery);
    }
    if (userId) {
      fetchSearchContact(userId, searchQuery);
    }
  }, [searchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setSelectedListName(event.target.value);
    fetchListOptions(event.target.value);
  };

  const handleSearchClick = () => {
    if (userId) {
      fetchSearchContact(userId, searchQuery);
    }
    fetchListOptions();
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearchClick();
  };

  const handleContactClick = (listId: number, contact: Option) => {
    navigate(`/lists/${listId}`, { state: { listId, contact } });
  };

  const handleContactDetail = (contactId: number, contact: any) => {
    navigate(`/contacts/${contactId}`, { state: { contactId, contact } });
  };

  const renderCards = (data: any[]) => {
    return data.map((card, index) => (
      <Box
        key={index}
        display="flex"
        alignItems="center"
        sx={{
          "&:hover": {
            borderColor: "#3d4592",
          },
        }}
      >
        <Box flex="1">
          <Typography
            variant="h6"
            color="#428bca"
            fontSize={"16px"}
            fontWeight={"bold"}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleContactDetail(card.id, card);
              }}
              style={{ textDecoration: "none", color: "#428bca" }}
            >
              {card.title}
            </a>
          </Typography>
          <Typography variant="body2" color="#999c9e" fontSize={"12px"}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleContactDetail(card.id, card);
              }}
              style={{ color: "#006621" }}
            >
              {config.googleRedirectUri}/contacts/{card.id}
            </a>
          </Typography>
          <Typography variant="body2" color="#999c9e" fontSize={"12px"}>
            Number:{card.number},Company:{card.company},Note:{card.notes}
          </Typography>
          <Divider
            style={{
              marginBottom: "1.5rem",
              marginTop: "0.5rem",
              borderWidth: "1px 10px",
            }}
          />
        </Box>
      </Box>
    ));
  };

  const renderCardList = (data: any[]) => {
    return data.map((cardData, index) => (
      <Box
        key={index}
        display="flex"
        alignItems="center"
        sx={{
          "&:hover": {
            borderColor: "#3d4592",
          },
        }}
      >
        <Box flex="1">
          <Typography
            variant="h6"
            color="#428bca"
            fontSize={"16px"}
            fontWeight={"bold"}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleContactClick(cardData.id, cardData);
              }}
              style={{ textDecoration: "none", color: "#428bca" }}
            >
              {cardData.title}
            </a>
          </Typography>
          <Typography variant="body2" color="#999c9e" fontSize={"12px"}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleContactClick(cardData.id, cardData);
              }}
              style={{ color: "#006621" }}
            >
              {config.googleRedirectUri}/lists/{cardData.id}
            </a>
          </Typography>

          <Divider
            style={{
              marginBottom: "1.5rem",
              marginTop: "0.5rem",
              borderWidth: "1px 10px",
            }}
          />
        </Box>
      </Box>
    ));
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#f3f3f4" }}>
      {isMobile && (
        <Divider
          style={{
            marginTop:
              !isOpen && isMobile ? "1rem" : isMobile ? "1rem" : "auto",
          }}
        />
      )}
      <Paper
        sx={{
          padding: "10px",
          backgroundColor: "#ffffff",
          width: isMobile ? "80%" : "95%",
          margin: "25px 50px 0px 25px",
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography sx={{ padding: "10px", fontSize: "24px" }}>
          Results found for:{" "}
          <span style={{ color: themestyle.colors.primary }}>{searchQuery}</span>
        </Typography>

        <form onSubmit={handleFormSubmit}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: !isOpen && isMobile ? "95%" : isMobile ? "95%" : "95%",
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                flex: 1,
                height: "50px",
                marginBottom: "10px",
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px",
                },
                "& .MuiInputAdornment-root": {
                  marginRight: "0px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      type="submit"
                      sx={{
                        textTransform: "none",
                        color: "white",
                        fontSize: "14px",
                        padding: "10px",
                        minWidth: "auto",
                        height: "55px",
                        backgroundColor: themestyle.colors.primary,
                        "&:hover": {
                          backgroundColor: themestyle.colors.primary,
                        },
                      }}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </form>

        <Divider
          style={{
            marginBottom: "1.5rem",
            marginTop: "0.5rem",
            borderWidth: "1px 10px",
          }}
        />
        {searchQuery && renderCards(cardData)}
        {searchQuery && renderCardList(cardDataList)}
      </Paper>
    </div>
  );
};

export default Search;
