import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  Chip,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AppleIcon from "@mui/icons-material/Apple";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import logo from "../assets/callonthego logo.png";
import axios from "axios";
import PheonixSideBar from "../Components/PheonixSideBar";
import SidebarProps from "../interfaces/SideBarProps";
import { TEXT, MODALTEXT } from "../constant";
import { config } from "../config";
import { useTheme, useMediaQuery } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { FaChartPie } from "react-icons/fa";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { HiOutlinePencilAlt } from "react-icons/hi";
import GroupsIcon from "@mui/icons-material/Groups";
import AdminDashboard from "./Admindashboard";
import themestyle from "../themestyle";
interface OptionType {
  id: string;
  name: string;
  contact_size: number;
}

interface MenuItem {
  text: string | JSX.Element;
  icon?: JSX.Element; 
  to?: string;
  onClick?: () => void;
}

const ITEM_SIZE = 40;
const MAX_CONTACT_HEIGHT = 160;
const MAX_REPORT_HEIGHT = 80;

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onToggle,
  dataFromAllContacts,
}) => {
  const token = localStorage.getItem("authtoken");
  const navigate = useNavigate();
  const location = useLocation();
  const [isContactExpanded, setIsContactExpanded] = useState(false);
  const [isReportExpanded, setIsReportExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contacts, setContacts] = useState<OptionType[]>([]);
  const [hasFetchedContacts, setHasFetchedContacts] = useState(false);
  const [contactSizes, setContactSizes] = useState<number[]>([]);
  const [selectedContactIndex, setSelectedContactIndex] = useState<
    number | null
  >(null);
  const [activeItem, setActiveItem] = useState<string>("");
  const [hasFetchedLists, setHasFetchedLists] = useState(false);
  const toggleContactExpand = () => {
    setIsContactExpanded(!isContactExpanded);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const toggleReportExpert = () => {
    setIsReportExpanded(!isReportExpanded);
  };

  useEffect(() => {
    if (dataFromAllContacts) {
      fetchContacts();
    }
  }, [dataFromAllContacts]);

  const isAdminDashboard = location.pathname === "/admin_dashboard";
  
  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/v1/lists`, {
        headers: { "x-cotg-authtoken": token },
      });

      if (Array.isArray(response.data.lists)) {
        const sortedLists = response.data.lists
          .map((list: OptionType) => ({ 
            id: list.id,
            name: list.name,
            contact_size: list.contact_size,
          }))
          .sort((a: OptionType, b: OptionType) => a.name.localeCompare(b.name));
        
        setContacts(sortedLists);
        localStorage.setItem("listIds", JSON.stringify(sortedLists.map((list: { id: any; }) => list.id)));
      }
    } catch (error) {
      console.error(MODALTEXT.ERROR, error);
    }
  }, [token]);

  const handleMenuItemClick = (
    itemText: string,
    shouldCloseDrawer: boolean = true
  ) => {
    setActiveItem(itemText);
    if (shouldCloseDrawer) {
      onToggle();
    }
  };

  useEffect(() => {
    if (dataFromAllContacts || isContactExpanded) {
      fetchContacts();
    }
  }, [dataFromAllContacts, isContactExpanded, fetchContacts])

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <FontAwesomeIcon
          icon={faTachometerAlt}
          size="lg"
          style={{
            fontSize: isOpen ? "1rem" : "1.2rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Dashboard"),
      to: "/",
    },
    {
      text: "Contacts",
      icon: (
        <PersonIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: toggleContactExpand,
    },
    {
      text: "Settings",
      icon: (
        <SettingsIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Settings"),
      to: "/users/edit",
    },
    {
      text: "Send Text",
      icon: (
        <CreateIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Send Text"),
      to: "/sendsms",
    },
    {
      text: "A/B Split",
      icon: (
        <HiOutlinePencilAlt
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("A/B Split"),
      to: "/absplit",
    },
    {
      text: "Send MMS",
      icon: (
        <ImageOutlinedIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Send MMS"),
      to: "/sendmms",
    },
    {
      text: "Reports",
      icon: (
        <FaChartPie
          style={{
            fontSize: isOpen ? "1.0rem" : "1.2rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: toggleReportExpert,
    },
    {
      text: "Import Contacts",
      icon: (
        <CloudUploadIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Import Contacts"),
      to: "/imports/new",
    },
    {
      text: "Referral",
      icon: (
        <FavoriteIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("Referral"),
      to: "/referral",
    },
    {
      text: (
        <Tooltip title="In progress">
          <span>Get it on Google Play</span>
        </Tooltip>
      ),
      icon: (
        <Tooltip title="In progress">
          <FontAwesomeIcon
            icon={faGooglePlay}
            size="lg"
            style={{
              fontSize: isOpen ? "1rem" : "1.2rem",
              marginLeft: isOpen ? "3px" : "7px",
            }}
          />
        </Tooltip>
      ),
    },
    {
      text: (
        <Tooltip title="In progress">
          <span>Get it on App Store</span>
        </Tooltip>
      ),
      icon: (
        <Tooltip title="In progress">
          <AppleIcon
            style={{
              fontSize: isOpen ? "1.2rem" : "1.4rem",
              marginLeft: isOpen ? "0px" : "5px",
            }}
          />
        </Tooltip>
      ),
    },
    {
      text: "Help",
      to: "/help",
    },
  ];

  const menuUserItems = [
    {
      text: "Dashboard",
      icon: (
        <FontAwesomeIcon
          icon={faTachometerAlt}
          size="lg"
          style={{
            fontSize: isOpen ? "1rem" : "1.2rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
    },
    {
      text: "User",
      icon: (
        <GroupsIcon
          style={{
            fontSize: isOpen ? "1.2rem" : "1.4rem",
            marginLeft: isOpen ? "0px" : "5px",
          }}
        />
      ),
      onClick: () => handleMenuItemClick("User"),
      to: "/admin_dashboard",
    },
  ];

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const contact = contacts[index];
    const listIds = JSON.parse(localStorage.getItem("listIds") || "[]");
    const listId = listIds[index];

    const isActive = index === selectedContactIndex;
    return (
      <ListItem
        style={style}
        sx={{
          backgroundColor: isActive ? "#293846" : "transparent",
          color: isActive ? "#FFFFFF" : "#a7b1c2",
          position: "relative",
          "&:hover": {
            backgroundColor: "#293846",
            color: "#FFFFFF",
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "3px",
            backgroundColor: themestyle.colors.primary,
          },
        }}
        onClick={() => handleContactClick(index, listId, contact)}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={contact.name}
            primaryTypographyProps={{
              fontSize: "13px",
            }}
          />
          <IconButton>
            <Chip
              sx={{
                color: "#FFFFFF",
                backgroundColor: "#F8AC58",
                borderRadius: 0.7,
                height: "20px",
                "& .MuiChip-label": {
                  fontSize: "10px",
                },
                "&:hover": {
                  backgroundColor: "#F8AC58",
                },
              }}
              label={contact.contact_size}
            />
          </IconButton>
        </ListItemButton>
      </ListItem>
    );
  };

  const reports = ({ index, style }: ListChildComponentProps) => {
    const items = ["Campaign", "Non Campaign"];
    const paths = ["/reports", "/reportsnongrp"];

    const handleReportClick = (index: number) => {
      navigate(paths[index]);
      setIsReportExpanded(false);
    };

    return (
      <ListItem
        onClick={() => handleReportClick(index)}
        style={{
          ...style,
          backgroundColor:
            index === selectedContactIndex ? "#293846" : "transparent",
          color: index === selectedContactIndex ? "#FFFFFF" : "#a7b1c2",
        }}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={items[index]}
            primaryTypographyProps={{ fontSize: "13px", marginLeft: "30px" }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const ReportsList = ({ index, style }: ListChildComponentProps) => {
    const navigate = useNavigate();
    const items = ["Campaign", "Non Campaign"];
    const paths = ["/reports", "/reportsnongrp"];

    const handleReportClick = (index: number) => {
      navigate(paths[index]);
      setIsReportExpanded(false);
    };

    return (
      <ListItem
        onClick={() => handleReportClick(index)}
        style={{
          ...style,
          backgroundColor:
            index === selectedContactIndex ? "#293846" : "transparent",
          color: index === selectedContactIndex ? "#FFFFFF" : "#a7b1c2",
        }}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={items[index]}
            primaryTypographyProps={{ fontSize: "13px", marginLeft: "30px" }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderRows = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const contact = contacts[index];
    const listIds = JSON.parse(localStorage.getItem("listIds") || "[]");
    const listId = listIds[index];

    return (
      <ListItem
        onClick={() => handleContactClick(index, listId, contact)}
        style={{
          ...style,
          backgroundColor:
            index === selectedContactIndex ? "#293846" : "transparent",
          color: index === selectedContactIndex ? "#FFFFFF" : "#a7b1c2",
        }}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={contact.name}
            primaryTypographyProps={{
              fontSize: "13px",
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const itemSize = 40;
  const maxHeight = 300;
  const listHeight = Math.min(contacts.length * itemSize, 160);
  const height = contacts.length <= 3 ? contacts.length * 40 : 5 * 52;
  const handleContactClick = (index: number, listId: any, contact: any) => {
    setSelectedContactIndex(index);
    navigate(`/lists/${listId}`, { state: { listId, contact } });
    {
      isMobile && setIsContactExpanded(false);
      if (isMobile || isTablet) {
        onToggle();
      }
    }
    {
      !isOpen && setIsContactExpanded(false);
      if (isMobile || isTablet) {
        onToggle();
      }
    }
  };

  const handleItemClick = (
    itemText: string | React.ReactNode,
    itemTo?: string
  ) => {
    const action = () => {
      switch (itemText) {
        case "Contacts":
          toggleContactExpand();
          break;
        case "Reports":
          toggleReportExpert();
          break;
        case "Get it on Google Play":
          break;
        case "Get it on App Store":
          break;
        default:
          if (itemTo) {
            navigate(itemTo);
            if (isMobile || isTablet) {
              onToggle();
            }
          }
          break;
      }
    };
    return action;
  };
  return (
    <>
      <div>
        <Drawer
          variant="permanent"
          sx={{
            width: isOpen ? (isMobile || isTablet ? "0px" : "230px") : 70,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              backgroundColor: "#2f4050",
              fontSize: "10px",
              width: isOpen ? (isMobile || isTablet ? "0px" : "230px") : "70px",
              marginLeft: isOpen
                ? isMobile || isTablet
                  ? "-100px"
                  : "0px"
                : "0px",
              boxSizing: "border-box",
              transition: "width 0.2s ease-in-out, visibility 0.2s linear",
              overflow: "hidden",
            },
          }}
          open={isOpen}
          onClose={onToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {isOpen && (
            <div
              style={{
                display: "flex",
                alignContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: isOpen ? "100px" : "40px",
                  height: "25px",
                  transition: "width 0.2s ease-in-out",
                  marginLeft: "20px",
                }}
              />
            </div>
          )}
          <List
            sx={{
              top: isOpen ? "5px" : "20px",
              marginTop: isOpen ? "5px" : "10px",
            }}
          >
            {isAdminDashboard
              ? menuUserItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <PheonixSideBar
                      icon={item.icon}
                      text={isOpen ? item.text : ""}
                      to={item.to || "#"}
                      onClick={handleItemClick(item.text, item.to)}
                      style={{
                        color: activeItem === item.text ? "#FFFFFF" : "#a7b1c2",
                      }}
                    />
                  </React.Fragment>
                ))
              : menuItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <PheonixSideBar
                      icon={item.icon}
                      text={isOpen ? item.text : ""}
                      to={item.to || "#"}
                      onClick={handleItemClick(item.text, item.to)}
                      style={{
                        color:
                          activeItem === item.text ||
                          (item.text === "Contacts" && isContactExpanded)
                            ? "#FFFFFF"
                            : "#a7b1c2",
                      }}
                    />
                    {item.text === "Contacts" && isOpen && (
                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          right: 13,
                          zIndex: 1,
                        }}
                      >
                        {isContactExpanded ? (
                          <ExpandMoreOutlinedIcon
                            sx={{
                              color: "#a7b1c2",
                              fontSize: "1.2rem",
                              marginTop: "-50px",
                              cursor: "pointer",
                            }}
                            onClick={toggleContactExpand}
                          />
                        ) : (
                          <ExpandLessOutlinedIcon
                            sx={{
                              color: "#a7b1c2",
                              fontSize: "1.2rem",
                              marginTop: "-50px",
                              cursor: "pointer",
                            }}
                            onClick={toggleContactExpand}
                          />
                        )}
                      </div>
                    )}
                    {item.text === "Contacts" &&
                      isContactExpanded &&
                      isOpen && (
                        <Box
                          style={{
                            textAlign: "left",
                            overflow: "hidden",
                            maxHeight: 160,
                          }}
                        >
                          <FixedSizeList
                            height={160}
                            width={250}
                            itemSize={itemSize}
                            itemCount={contacts.length}
                            overscanCount={5}
                            style={{ color: "#FFFFFF" }}
                          >
                            {renderRow}
                          </FixedSizeList>
                        </Box>
                      )}
                    {item.text === "Reports" && isReportExpanded && isOpen && (
                      <Box
                        style={{
                          textAlign: "left",
                          overflow: "hidden",
                          maxHeight: 80,
                        }}
                      >
                        <FixedSizeList
                          height={80}
                          width={250}
                          itemSize={40}
                          itemCount={2}
                          overscanCount={2}
                          style={{ color: "#FFFFFF" }}
                        >
                          {reports}
                        </FixedSizeList>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
          </List>
        </Drawer>
      </div>
      {isContactExpanded && (
        <Menu
          id="contacts-menu"
          anchorEl={anchorEl}
          open={!isOpen}
          onClose={() => setIsContactExpanded(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            marginLeft: "50px",
            marginTop: "70px",
            border: "none",
            "& .MuiPaper-root": {
              border: "none",
              backgroundColor: "#2E4050",
            },
            "& .MuiMenuItem-root": {
              "&:hover": {
                color: "#FFFFFF",
              },
            },
          }}
        >
          <FixedSizeList
            height={contacts.length * itemSize || 200}
            width={217}
            itemSize={itemSize}
            itemCount={contacts.length}
            overscanCount={5}
            style={{
              color: "#a7b1c2",
              backgroundColor: "#2E4050",
              border: "none",
            }}
          >
            {renderRows}
          </FixedSizeList>
        </Menu>
      )}
      {isReportExpanded && (
        <Menu
          id="reports-menu"
          anchorEl={anchorEl}
          open={!isOpen}
          onClose={() => setIsReportExpanded(false)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{
            marginLeft: "50px",
            marginTop: "270px",
            border: "none",
            "& .MuiPaper-root": { border: "none", backgroundColor: "#2E4050" },
            "& .MuiMenuItem-root": { "&:hover": { color: "#FFFFFF" } },
          }}
        >
          <FixedSizeList
            height={70}
            width={217}
            itemSize={35}
            itemCount={2}
            overscanCount={5}
            style={{
              color: "#a7b1c2",
              backgroundColor: "#2E4050",
              border: "none",
            }}
          >
            {ReportsList}
          </FixedSizeList>
        </Menu>
      )}
    </>
  );
};

export default Sidebar;
