import React, { useMemo } from "react";
import { Box, Divider, Paper, Typography, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { TEXT, BUTTON, CARDURL, URL, HELP } from "../constant";
import { useSidebar } from "./sidebarcontext";
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import main from "../assets/CALLontheGO_icon_.png";
import link from "../assets/integration_line_logo.png";
import contact from "../assets/contacts.png";
import on from "../assets/on.png";
import ads from "../assets/ads.png";
import calender from "../assets/calculator.png";
import hub from "../assets/hub.png";
import mail from "../assets/mail.png";

const cardData = [
  {
    title: "Add new CallOnTheGo call lists as new CRM events",
    description: "CRM Connector + CallOnTheGo",
    cardUrl: CARDURL.FIRST_URL,
    imageUrl1: link,
    imageUrl2: main,
  },
  {
    title: "Add new Google contacts as new CallOnTheGo contacts",
    description: "CallOnTheGo + Google Contacts",
    cardUrl: CARDURL.SECOND_URL,
    imageUrl1: main,
    imageUrl2: contact,
  },
  {
    title: "Add new Gmail emails to new CallOnTheGo lists [Business Gmail Accounts Only]",
    description: "CallOnTheGo + Gmail",
    cardUrl: CARDURL.THIRD_URL,
    imageUrl1: main,
    imageUrl2: mail,
  },
  {
    title: "Add new or updated Google Calendar events to new CallOnTheGo lists",
    description: "CallOnTheGo + Google Calendar",
    cardUrl: CARDURL.FOURTH_URL,
    imageUrl1: main,
    imageUrl2: calender,
  },
  {
    title: "Add new CallOnTheGo contacts for lists as HubSpot CRM contacts",
    description: "CallOnTheGo + HubSpot",
    cardUrl: CARDURL.FIFTH_URL,
    imageUrl1: main,
    imageUrl2: hub,
  },
  {
    title: "Add new Ontraport contacts as CallOnTheGo contacts",
    description: "CallOnTheGo + Ontraport",
    cardUrl: CARDURL.SIXTH_URL,
    imageUrl1: main,
    imageUrl2: on,
  },
  {
    title: "Add new CallOnTheGo contacts as new Ontraport contacts",
    description: "Ontraport + CallOnTheGo",
    cardUrl: CARDURL.SEVENTH_URL,
    imageUrl1: on,
    imageUrl2: main,
  },
  {
    title: "Add new HubSpot contacts as new CallOnTheGo contacts",
    description: "CallOnTheGo + HubSpot",
    cardUrl: CARDURL.EIGHT_URL,
    imageUrl1: main,
    imageUrl2: hub,
  },
  {
    title: "Add new CallOnTheGo contacts as new or updated HubSpot contacts",
    description: "CallOnTheGo + HubSpot",
    cardUrl: CARDURL.NINE_URL,
    imageUrl1: main,
    imageUrl2: hub,
  },
  {
    title: "Create Contact on CallOnTheGo for new Google Ads Leads",
    description: "CallOnTheGo + Google Ads",
    cardUrl: CARDURL.TENTH_URL,
    imageUrl1: main,
    imageUrl2: ads,
  },
];

const ZapCard: React.FC<{ card: any; isMobile: boolean }> = ({ card, isMobile }) => (
  <a href={card.cardUrl} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      alignItems="center"
      padding="10px"
      boxShadow={1}
      marginBottom="30px"
      border="1px solid #999c9e"
      borderRadius="4px"
      sx={{ "&:hover": { borderColor: "#3d4592" } }}
    >
      <Box display="flex" alignItems="center" marginRight={isMobile ? 0 : "10px"} marginBottom={isMobile ? "10px" : 0}>
        <img src={card.imageUrl1} alt={`${card.title} icon 1`} style={{ marginRight: "5px", width: "24px", height: "24px", border: "1px solid #f3f3f4", padding: "2px", borderRadius: "4px" }} />
        <img src={card.imageUrl2} alt={`${card.title} icon 2`} style={{ width: "24px", height: "24px", border: "1px solid #f3f3f4", padding: "2px", borderRadius: "4px" }} />
      </Box>
      <Box flex="1" textAlign={isMobile ? "left" : "inherit"} alignSelf={isMobile ? "flex-start" : "inherit"} marginBottom={isMobile ? "10px" : 0}>
        <Typography variant="h6" color="#000000" fontSize="14px" fontWeight="bold">{card.title}</Typography>
        <Typography variant="body2" color="#000000" fontSize="14px">{card.description}</Typography>
      </Box>
      <Button variant="contained" style={{ backgroundColor: "#000000", width: isMobile ? "80%" : "10%", textTransform: "none", fontWeight: "bold" }}>{BUTTON.USEZAP}</Button>
    </Box>
  </a>
);

const Help: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen } = useSidebar();

  const renderedCards = useMemo(() => cardData.map((card, index) => <ZapCard key={index} card={card} isMobile={isMobile} />), [isMobile]);

  const videos = [
    "https://www.youtube.com/embed/8unoEhnHC5c?si=apI4oF5Gh1c8w9y4",
    "https://www.youtube.com/embed/iCp2RlsXGws?si=CrIxl0AeaOkX3ZwE",
  ];

  return (
    <>
    {isMobile && <Divider  />}
    <Box width="auto" bgcolor="#f3f3f4" padding="20px">
      
      <Paper sx={{  padding: "10px", fontFamily: "sans-serif", color: "#676a6c", fontSize: "13px" }}>
        {TEXT.INTEGRATIONS}
        <Divider sx={{ marginY: "1rem" }} />
        <Typography variant="h6" fontWeight="bold" fontSize="14px">{HELP.TEXT1}</Typography>
        <Typography variant="body2" fontSize="14px" mt="10px">
          <a href="https://zapier.com/apps/callonthego/integrations" style={{ color: "#478DCB", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">Zapier</a> {HELP.TEXT2}
        </Typography>
        <Typography variant="body2" fontSize="14px" mt="10px">{HELP.TEXT3}</Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="14px" mt="10px">{HELP.TEXT4}</Typography>
        <Typography variant="body2" fontSize="14px" mt="10px" mb="10px">
          {HELP.TEXT5} <a href="https://zapier.com/sign-up" style={{ color: "#478DCB", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">Zapier account</a> {HELP.TEXT6}
        </Typography>
        {renderedCards}
        <Typography variant="body1" color="#000000" textAlign="center" padding="0 0 20px 0">
          {TEXT.NOT_SEEING} <br />
          <a href={URL.CREATE_FROM_SCRATCH_URL} style={{ color: "#3d4592" }} target="_blank" rel="noopener noreferrer">{HELP.TEXT7}</a> or <a href={URL.LEARN_MORE_URL} style={{ color: "#3d4592" }} target="_blank" rel="noopener noreferrer">{HELP.TEXT8}</a>.
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="14px">{HELP.TEXT9}</Typography>
        <Typography variant="body2" fontSize="14px" mt="10px" ml="30px">
          1. Log in to your <a href="https://zapier.com/app/login" style={{ color: "#478DCB", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">Zapier account</a> or create a new account.<br />
          2. Navigate to "Connected Accounts" from the top menu bar.<br />
          3. Now click on "Connect new account" and search for "CallOnTheGo".<br />
          4. Use your credentials to connect your CallOnTheGo account to Zapier.<br />
          5. Once that's done you can start creating an automation! Use a pre-made Zap or create your own with the Zap Editor. Creating a Zap requires no coding knowledge and you'll be walked step-by-step through the setup.<br />
          6. Need inspiration? See everything that's possible with <a href="https://zapier.com/apps/callonthego/integrations" style={{ color: "#478DCB", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">CallOnTheGo and Zapier</a>
        </Typography>
      </Paper>
      <Paper sx={{ mt: "30px", padding: "10px" }}>
        <Typography variant="h6" fontWeight="bold" color="#676a6c" fontSize="13px">
          <IconButton size="small"><HelpOutlinedIcon /></IconButton> Help
        </Typography>
        <Typography variant="body2" fontSize="14px" mt="-5px" ml="30px" pb="30px" color="#676a6c">
          Email: <a href="mailto:support@CallOnTheGo.com" style={{ color: "#478DCB", textDecoration: "none" }}>support@CallOnTheGo.com</a><br />
          Phone: 1-888-301-2726<br />
          Text: 325.200.0007
        </Typography>
      </Paper>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-around", mt: "30px" }}>
        {videos.map((src, index) => (
          <iframe
            key={index}
            width={isMobile ? "100%" : "620"}
            height={isMobile ? "200" : "315"}
            src={src}
            title={`YouTube video ${index + 1}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ marginBottom: isMobile ? "20px" : "0" }}
          />
        ))}
      </Box>
    </Box>
    </>
  );
};

export default React.memo(Help);