import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../config";
import CircularProgress from "@mui/material/CircularProgress";

const SendMMS: React.FC = () => {
  var sessionId = sessionStorage.getItem("sessionId") || "";
  const [iframeContent, setIframeContent] = useState<string>("");
  const [loading, setloading] = useState<boolean>(true);
  const email = sessionStorage.getItem("email");

   const url = new URL(`${config.autoLoginURL}`);
      const domain = url.hostname; 
    
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30); 
    
      const expiryString = expiryDate.toUTCString();
  
      document.cookie = `sessionId=${sessionId}; path=/; domain=${domain}; expires=${expiryString}`;
  

  const modifyHtmlContent = (html: string, sessionId: string) => {
    let modifiedHtmlContent = html
      .replace(
        /https:\/\/app\.telca\.com\/static\//g,
        `${config.autoLoginURL}/`
      )
      .replace(/<nav[\s\S]*?<\/nav>/g, "")
      .replace(/ajax_mms.php/g, `/bff/ajax_mms.php?sessionId=${sessionId}`)
      .replace(
        /<body>/,
        `<body><div id="wrapper" style="margin: 0; padding: 0;">`
      )
      .replace(/<\/body>/, `</div></body>`)
      .replace(/<head>/i, `<head><base href="${config.autoLoginURL}/">`);

    modifiedHtmlContent += `
      <style>
        * { margin: 0; padding: 0; }
        #page-wrapper { width: auto !important; }
      </style>
      <script>
        async function sendMMSRequest() {
          const sessionId = new URLSearchParams(window.location.search).get('sessionId');
          if (!sessionId) {
            console.error('No sessionId found in URL');
            return;
          }
          const response = await fetch('/ajax_mms.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json'
            },
            body: new URLSearchParams({ sessionId }).toString()
          });
          const result = await response.json();
        }
        window.onload = sendMMSRequest;
      </script>`;

    return modifiedHtmlContent;
  };

  const sendmms = async () => {
    try {
      if (sessionId) {
        const newSessionId = sessionId;
        const sendMMSResponse = await axios.get(
          `${config.autoLoginURL}/proxy/sendmms?sessionId=${newSessionId}`,
          {
            withCredentials: true,
            headers: {
              Accept: "text/html",
              "Content-Type": "application/json",
              Cookie: `PHPSESSID=${newSessionId}`,
            },
          }
        );

        const responseHtml = sendMMSResponse.data.toLowerCase();
        if (
          responseHtml.includes("<title>telca</title>") &&
          responseHtml.includes("<h3>login</h3>") &&
          responseHtml.includes('name="email"')
        ) {
          const autoLoginResponse = await axios.post(
            `${config.autoLoginURL}/v3/autologin`,
            { email: email },
            {
              withCredentials: true,
              headers: { "Content-Type": "application/json" },
            }
          );

          if (autoLoginResponse.data && autoLoginResponse.data.session_id) {
            sessionStorage.setItem(
              "sessionId",
              autoLoginResponse.data.session_id
            );
            sessionId = autoLoginResponse.data.session_id;
          }

          const updatedSendMmsResponse = await axios.get(
            `${config.autoLoginURL}/proxy/sendmms?sessionId=${sessionId}`,
            {
              withCredentials: true,
              headers: {
                Accept: "text/html",
                "Content-Type": "application/json",
                Cookie: `PHPSESSID=${sessionId}`,
              },
            }
          );
          setIframeContent(
            modifyHtmlContent(updatedSendMmsResponse.data, sessionId)
          );
        }else{
          setIframeContent(modifyHtmlContent(sendMMSResponse.data, sessionId));
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    sendmms();
  }, [sessionId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        minHeight: "85vh",
        marginTop: "-3px",
        marginLeft: "-1px",
      }}
    >
      {" "}
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <iframe srcDoc={iframeContent} width="100%" height={720} />
      )}
    </div>
  );
};

export default SendMMS;
